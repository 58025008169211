import { dbCollections } from "@/utils/firebaseCollection.js";
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
const db = firebase.firestore()

export const updateFiledCollectionWithStudentID = (ele,id,type) => {
    return new Promise(async(resolve, reject) => {
        try {
            let updateData;
            if(type == 1) {
                updateData = {
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    assigneeStudent:firebaseTimeStamp.firestore.FieldValue.arrayUnion(id)
                }
            } else if(type == 2) {
                updateData = {
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    assigneeStudent:firebaseTimeStamp.firestore.FieldValue.arrayRemove(id)
                }
            }
            if(ele.bouId != null && ele.bouId != ''){
                await db.collection(dbCollections.UNIVERSITIESFIELDS).doc(ele.bouId).update(updateData)
            }
            if(ele.scId != null && ele.scId != ''){
                await db.collection(dbCollections.COLLEGESFIEDLS).doc(ele.scId).update(updateData)
            }
            if(ele.edId != null && ele.edId != ''){
                await db.collection(dbCollections.DEGREEFIELD).doc(ele.edId).update(updateData)
            }
            if(ele.bsfId != null && ele.bsfId != ''){
                await db.collection(dbCollections.BRANCHSTREAMFIELDS).doc(ele.bsfId).update(updateData)
            }
            resolve(true)
        } catch (error) {
            reject(error)
        }
    })
}


export const updateFiledCollectionWithStudentIDV2 = (ele,id,type) => {
    return new Promise(async(resolve, reject) => {
        try {
            let updateData;
            if(type == 1) {
                updateData = {
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                }
            } else if(type == 2) {
                updateData = {
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                }
            }
            if(ele.bouId != null && ele.bouId != ''){
                if(type == 1) {
                    updateData.assigneeStudent = firebaseTimeStamp.firestore.FieldValue.arrayUnion(id)
                    await db.collection(dbCollections.UNIVERSITIESFIELDS).doc(ele.bouId).update(updateData)
                } else if(type == 2) {
                    db.collection(dbCollections.UNIVERSITIESFIELDS).doc(ele.bouId).get().then(async(res)=>{
                        if(res.data().assigneeStudent != undefined && res.data().assigneeStudent != null && res.data().assigneeStudent.length > 0){
                            let updateData = {
                                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                            }
                            if(!res.data().assigneeStudent.includes(id)){
                                updateData.assigneeStudent = firebaseTimeStamp.firestore.FieldValue.arrayUnion(id)
                            } else {
                                updateData.assigneeStudent = firebaseTimeStamp.firestore.FieldValue.arrayRemove(id)
                            }
                            await db.collection(dbCollections.UNIVERSITIESFIELDS).doc(ele.bouId).update(updateData)
                        }
                    })
                }
            }
            if(ele.scId != null && ele.scId != ''){
                await db.collection(dbCollections.COLLEGESFIEDLS).doc(ele.scId).update(updateData)
            }
            if(ele.edId != null && ele.edId != ''){
                await db.collection(dbCollections.DEGREEFIELD).doc(ele.edId).update(updateData)
            }
            if(ele.bsfId != null && ele.bsfId != ''){
                await db.collection(dbCollections.BRANCHSTREAMFIELDS).doc(ele.bsfId).update(updateData)
            }
            resolve(true)
        } catch (error) {
            reject(error)
        }
    })
}