<template>
<div>
    <div class="create_new_inquiry" v-show="!isSpiner">
        <!-- Personal Information -->
        <validation-observer ref="profileStudentVali">
        <b-card
            title="Personal Information"
        >

            <!-- form -->
            <b-form>
                <b-row class="personal_information_wrapper">
                    <!-- image -->
                    <b-col md="1" class="afterUploadImg">
                        <b-row>
                            <b-col md="12">
                                <img v-if="previewImage.length==0 && currentUser.profilePic !== ''" :src="currentUser.profilePic" alt="profile image2" width="160px" height="145px" class="profile_img">
                                <img v-else :src="previewImage[0]" v-show="previewImage.length>0" alt="profile image1" width="160px" height="145px" class="profile_img">
                                <b-spinner variant="primary" v-if="uploadingImage" large style="position: absolute;z-index:999; top:50px;left:40%"/>
                            </b-col>
                            <b-col md="12">
                                <validation-provider
                                    #default="{ errors }"
                                    name="image file"
                                    rules=""
                                >
                                    <b-form-file
                                        id="imagePicker"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..."
                                        accept="image/*"
                                        @change="checkFile"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <input type="text" v-model="currentUser.profilePic" v-show="false">
                                    <small class="text-danger" v-if="isImage">{{ errors[0] }}</small>
                                    <!-- <small class="text-danger" v-else>Please select an image file.</small> -->
                                </validation-provider>
                            </b-col>
                        </b-row>                  
                    </b-col>  

                    <b-col md="11">
                        <b-row>
                            <!-- Name -->
                            <b-col md="3">
                                <b-form-group label="First Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="first name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="currentUser.firstName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="currentUser.firstName=currentUser.firstName.trim()"
                                        :disabled="true"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>  

                            <b-col md="3">
                                <b-form-group label="Last Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="last name"
                                    rules="required|alpha"
                                >
                                    <b-form-input
                                        v-model="currentUser.lastName"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        :disabled="true"
                                        @blur="currentUser.lastName=currentUser.lastName.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>                           

                            <!-- Email -->
                            <b-col md="3">
                                <b-form-group label="Email">                               
                                    <b-form-input
                                        v-model="currentUser.email"                                       
                                        placeholder="Enter Text"
                                        @blur="currentUser.email=currentUser.email.toLowerCase().trim()"
                                        disabled
                                    />   
                                </b-form-group>                            
                            </b-col>

                            <!-- Phone -->
                            <b-col md="3">
                                <b-form-group label="Mobile Number" class="position-relative cls_phoneno_wrapper">
                                    <span class="phoneno_prefix"><phoneCountryCodeVue @country-selected="onCountrySelected" :selectedCode="selectedCountry"/></span>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="mobile no."
                                        rules="required|integer"
                                    >
                                        <b-form-input
                                            v-model="currentUser.mobileNumber"
                                            name="mobile no."
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter number"
                                            @blur="currentUser.mobileNumber=currentUser.mobileNumber.trim()"
                                            @keypress="isOnlyInteger"
                                            @input="checkValidPhoneNumber"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="errors.length == 0 && !validNumberOrNot">Please provide valid phone number.</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Date -->
                            <b-col md="3">
                                <b-form-group label="Date of Birth">
                                <validation-provider
                                    #default="{ errors }"
                                    name="date"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="example-input"
                                        v-model="currentUser.dob"
                                        type="date"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                        :state="errors.length > 0 ? false:null"
                                        :max="getMaxDate()"
                                        @change="changeBirthDate()"
                                        class="input-date"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Gender -->
                            <b-col md="3">
                                <b-form-group label="Gender">
                                <validation-provider
                                    #default="{ errors }"
                                    name="gender"
                                    rules="required"
                                >
                                <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                <!-- <div> -->
                                    <b-form-radio
                                        v-model="currentUser.gender"
                                        plain
                                        name="gender"
                                        value="Male"
                                    >
                                        Male
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="currentUser.gender"
                                        plain
                                        name="gender"
                                        value="Female"
                                    >
                                        Female
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="currentUser.gender"
                                        plain
                                        name="gender"
                                        value="Other"
                                    >
                                        Other
                                    </b-form-radio>
                                </div>
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Select Representative -->
                            <b-col md="3">
                                <b-form-group label="Representative">                                
                                <b-form-input
                                    id="example-input"
                                    v-model="currentUser.representative"                                        
                                    type="text"                                        
                                    autocomplete="off"
                                    show-decade-nav
                                    disabled
                                />                               
                                </b-form-group>
                            </b-col>

                            <!-- intake -->
                            <b-col md="3">
                                <b-form-group label="Intake">
                                <validation-provider
                                    #default="{ errors }"
                                    name="intake"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="intake"
                                        :options="intakeOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Year -->
                            <b-col md="3">
                                <b-form-group label="Year">
                                <validation-provider
                                    #default="{ errors }"
                                    name="year"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="year"
                                        :options="yearOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- Select Document Module -->
                            <!-- <b-col md="3">
                                <b-form-group label="Select Document Module">                               
                                <span v-for='(data,index) in currentUser.documentModule' :key="index">
                                    {{data.title}}
                                    <span v-if='index != Number(currentUser.documentModule.length - 1)'>,</span>
                                </span>                                
                                </b-form-group>
                            </b-col> -->
                        </b-row>
                    </b-col>               
                </b-row>
            </b-form>
        </b-card>   

         <b-row class="match-height">
            <!-- Communication Address -->
            <b-col md="12">
                <b-card
                    title="Communication Address"
                >
                    <!-- form -->
                    <b-form>
                        <b-row>
                            <!-- Address Line 1 -->
                            <b-col md="3">
                                <b-form-group label="Address Line 1">
                                <validation-provider
                                    #default="{ errors }"
                                    name="address line 1"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="currentUser.addressLine1"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="currentUser.addressLine1=currentUser.addressLine1.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Address Line 2 -->
                            <b-col md="3">
                                <b-form-group label="Address Line 2">
                                <validation-provider
                                    #default="{ errors }"
                                    name="address line 2"
                                    rules=""
                                >
                                    <b-form-input
                                        v-model="currentUser.addressLine2"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Text"
                                        @blur="currentUser.addressLine2=currentUser.addressLine2.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Country -->
                            <b-col md="3">
                                <b-form-group label="Country">
                                <validation-provider
                                    #default="{ errors }"
                                    name="country"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="currentUser.country"
                                        :options="currentUser.countryOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                        @change="getStates()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- State -->
                            <b-col md="3">
                                <b-form-group label="State">
                                <validation-provider
                                    #default="{ errors }"
                                    name="state"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="currentUser.state"
                                        :disabled="!currentUser.country"
                                        :options="currentUser.stateOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                        @change="getCities()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- City -->
                            <b-col md="3">
                                <b-form-group label="City">
                                <validation-provider
                                    #default="{ errors }"
                                    name="city"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="currentUser.city"
                                        :disabled="!currentUser.state"
                                        :options="currentUser.cityOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Pincode -->
                            <b-col md="3">
                                <b-form-group label="Pincode">
                                <validation-provider
                                    #default="{ errors }"
                                    name="pincode"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="currentUser.pincode"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Enter Pincode"
                                        @blur="currentUser.pincode=currentUser.pincode.trim()"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card>
            </b-col>
            <!-- Educational Details -->
            <b-col md="12">
                <div
                    class="card"
                >
                    <!-- form -->
                    <div class="card-body edit_student_main">
                        <h4 class="card-title">
                            Education Details
                        </h4>
                        <b-form>
                            <b-row v-for="(data,index) in currentUser.educationDetails" :key="'educationDetails'+index">
                                <!-- Education -->
                                <b-col md="3" v-if="index<2">
                                    <b-form-group label="Education">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="education"
                                        :vid="'education'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.education"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            :disabled="(data.key && typeof(data.key) != 'undefined')"
                                            @blur="data.education=data.education.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-if="data.edId != undefined">
                                    <b-form-group label="Degree">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="education"
                                        :vid="'education1'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.edId"
                                            :options="educationOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'edId','education',1)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-if="data.bsfId != undefined">
                                    <b-form-group label="Branch/Stream/Field">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="branch/stream/field"
                                        :vid="'branch/stream/field1'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.bsfId"
                                            :options="branchFieldOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'bsfId','branchOrStreamOrFieldName',4)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.bsfId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="branch/stream/field"
                                        :vid="'branch/stream/field2'+index"
                                        :rules="data.bsfId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            v-if="data.bsfId == 'others'"
                                            v-model="data.branchOrStreamOrFieldName"
                                            :state="errors.length > 0 ? false:null"
                                            :disabled="(data.key && typeof(data.key) != 'undefined')"
                                            placeholder="Enter Text"
                                            @blur="data.branchOrStreamOrFieldName=data.branchOrStreamOrFieldName.trim()"
                                        />
                                        <small class="text-danger" v-if="data.bsfId == 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-if="data.bsfId == undefined && data.edId == undefined && index>=2">
                                    <!-- <b-form-group :label="index == 0 ? '10th/SSC' : index == 1 ? '12th/HSC/Diploma' :'Education'"> -->
                                    <b-form-group :label="'Education'">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="education"
                                        :vid="'education2'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.education"
                                            :state="errors.length > 0 ? false:null"
                                            :disabled="(data.key && typeof(data.key) != 'undefined')"
                                            placeholder="Enter Text"
                                            @blur="data.education=data.education.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- School/College Name -->
                                <b-col md="3" v-if="index<2">
                                    <b-form-group label="School/ College Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.schoolOrCollegeName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.schoolOrCollegeName=data.schoolOrCollegeName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col> 
                                <b-col md="3" v-else-if="data.scId != undefined">
                                    <b-form-group label="School/ College Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name2'+index"
                                        :rules="'required'"
                                    >
                                        <b-form-select
                                            v-model="data.scId"
                                            :options="schoolOrCollegeNameOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'scId','schoolOrCollegeName',2)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.scId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="branch/stream/field"
                                        :vid="'branch/stream/field'+index"
                                        :rules="data.scId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            v-if="data.scId == 'others'"
                                            v-model="data.schoolOrCollegeName"
                                            :state="errors.length > 0 ? false:null"
                                            :disabled="(data.key && typeof(data.key) != 'undefined')"
                                            placeholder="Enter Text"
                                            @blur="data.schoolOrCollegeName=data.schoolOrCollegeName.trim()"
                                        />
                                        <small class="text-danger" v-if="data.scId == 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-else>
                                    <b-form-group label="School/ College Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="school/college name"
                                        :vid="'school/college name3'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.schoolOrCollegeName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.schoolOrCollegeName=data.schoolOrCollegeName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col> 

                                <!-- Board/University Name -->
                                <b-col md="3" v-if="index<2">
                                    <b-form-group label="Board/University Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.boardUniversityName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.boardUniversityName=data.boardUniversityName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-else-if="data.bouId != undefined">
                                    <b-form-group label="Board/ University Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name1'+index"
                                        :rules="'required'"
                                    >
                                        <b-form-select
                                            v-model="data.bouId"
                                            :options="boardUniversityNameOptions"
                                            :state="errors.length > 0 ? false:null"
                                            @change="(e)=>{educationFiledSet(e,index,'bouId','boardUniversityName',3)}"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger" v-if="data.bouId !== 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name2'+index"
                                        :rules="data.bouId == 'others'?'required':''"
                                    >
                                        <b-form-input
                                            v-model="data.boardUniversityName"
                                            v-if="data.bouId == 'others'"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.boardUniversityName=data.boardUniversityName.trim()"
                                        />
                                        <small class="text-danger" v-if="data.bouId == 'others'">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" v-else>
                                    <b-form-group label="Board/University Name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="board/university name"
                                        :vid="'board/university name3'+index"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model="data.boardUniversityName"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Text"
                                            @blur="data.boardUniversityName=data.boardUniversityName.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- CGPA/PERCENTAGE -->
                                <b-col md="3">
                                    <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="cgpi/percentage"
                                        :vid="'cgpi/percentage'+index"
                                        rules="required"
                                    >
                                    <div style="display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; height: 38px; margin-top: 30px;">
                                    <!-- <div> -->
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="Percentage"
                                        >
                                            Percentage
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="CGPI"
                                        >
                                            CGPI
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="data.cgpa_percentage"
                                            plain
                                            value="Grade"
                                        >
                                            Grade
                                        </b-form-radio>
                                    </div>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- CGPA -->
                                <b-col md="3" v-if="data.cgpa_percentage!='Grade'">
                                    <b-form-group :label="data.cgpa_percentage">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="data.cgpa_percentage"
                                        :vid="data.cgpa_percentage+index"
                                        :rules="data.cgpa_percentage!='CGPI'?'required|max_value:100':'required|max_value:10'"
                                    >
                                        <b-form-input
                                            v-model="data.cgpa_percentageValue"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Number"
                                            @blur="data.cgpa_percentageValue=data.cgpa_percentageValue.trim()"
                                            @keypress="isOnlyDecimal"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <!-- CGPA -->
                                <b-col md="3" v-else>
                                    <b-form-group :label="data.cgpa_percentage">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="data.cgpa_percentage"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.cgpa_percentageValue"
                                            :options="gradeOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <!-- Year of passing -->
                                <b-col md="3">
                                    <b-form-group label="Year of Passing">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="year of passing"
                                        :vid="'year of passing'+index"
                                        rules="required"
                                    >
                                        <b-form-select
                                            v-model="data.yearOfPassing"
                                            :options="passingYearOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col style="text-align: right">
                                    <b-button variant="outline-danger" v-if="index>1" @click.prevent="removeEducationRow(index)">
                                        Remove Education
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                    <div class="card-body add_edu_link" style="border-top:1px solid #cfcfcf;text-decoration:underline;color:#3b3bf6;font-weight:600;">
                        <a @click.prevent="addNewEducationRow()">Add a Education</a>
                    </div>
                </div>
            </b-col>
           <b-col>
                <b-row>
                    <!-- Languages -->
                    <b-col md="12">
                        <b-card
                            title="German Language"
                        >
                            <!-- form -->
                            <b-form>
                                <b-row>
                                    <!-- Select Language -->
                                    <!-- <b-col md="6">
                                        <b-form-group label="Language">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select representative"
                                            rules="required"
                                        >
                                            <b-form-select
                                                v-model="currentUser.language"
                                                :options="currentUser.languageOptions"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col> -->
                                    <!-- German Language -->
                                    <b-col md="6">
                                        <b-form-group label="German Language">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="german language"
                                            rules="required"
                                        >
                                        <div style="display:flex;justify-content:space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                            <b-form-radio
                                                v-model="currentUser.germanLanguage"
                                                plain
                                                name="german language"
                                                value="None"
                                            >
                                                None
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="currentUser.germanLanguage"
                                                plain
                                                name="german language"
                                                value="A1"
                                            >
                                                A1
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="currentUser.germanLanguage"
                                                plain
                                                name="german language"
                                                value="A2"
                                            >
                                                A2
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="currentUser.germanLanguage"
                                                plain
                                                name="german language"
                                                value="B1"
                                            >
                                                B1
                                            </b-form-radio>
                                            <b-form-radio
                                                v-model="currentUser.germanLanguage"
                                                plain
                                                name="german language"
                                                value="B2"
                                            >
                                                B2
                                            </b-form-radio>
                                        </div>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                    <!-- Exams -->
                    <b-col md="12">
                        <b-card
                            title="Exams"
                        >
                            <!-- form -->
                            <b-form>
                                <b-row>
                                    <!-- Exams -->
                                    <b-col md="6">
                                        <b-form-group label="Exams">
                                        <validation-provider
                                        #default="{ errors }"
                                        name="exams"
                                        rules=""
                                        >
                                        <v-select
                                            v-model="currentUser.exams"
                                            label="title"
                                            :value='currentUser.exams'
                                            multiple
                                            :options="currentUser.examOptions"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Select"                                            
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Band Scores -->
                                    <b-col md="6" v-for="(data,index) in currentUser.exams" :key="'band scores'+index">
                                        <b-form-group :label="data.title">
                                        <validation-provider
                                        #default="{ errors }"
                                        :name="data.title"
                                        rules="required"
                                        >
                                        <b-form-input
                                            v-model="data.score"
                                            :state="errors.length > 0 ? false:null"
                                            placeholder="Enter Number"
                                            @blur="data.score=data.score.trim()"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <!-- Field of Interest -->
            <b-col md="6">
                <b-row v-if="false">
                    <b-col md="12">
                        <b-card title="Student Interest">
                            <b-form-group label="Area of Interest">
                                <b-form-input
                                    v-model="currentUser.areaOfInterest"
                                    placeholder="Enter Text"
                                    @blur="currentUser.areaOfInterest=currentUser.areaOfInterest.trim()"
                                />
                            </b-form-group>
                        </b-card>
                    </b-col>

                    <!-- Field of Interest Hidden -->
                    <b-col md="12" v-if="false">
                        <b-card title="Field of Interest">
                        <span v-if='currentUser.fieldofIntrest!==null'>
                        <span v-show="editable==false" v-for="(intData,ind) in currentUser.fieldofIntrest" :key="ind">
                        <b-form-group v-if='intData.course !== undefined || intData.course !== null' :label="intData.course.name">
                            <span v-show="editable==false">
                                <p v-if='intData.course !== undefined || intData.course !== null'>{{intData.field.name}}</p>
                            </span>
                        </b-form-group>
                        </span> 
                        </span>
                            <span v-show="editable!==false"> 
                            <!-- form -->
                            <validation-observer ref="intrestingFieldsForm">
                            <b-form>
                                <b-row v-for="(data,index) in currentUser.fieldofIntrest" :key="index" :style="currentUser.fieldofIntrest.length>0?'border-bottom:1px solid #cfcfcf':''" style="margin-top:10px">
                                    <!-- Select Field -->   
                                    <b-col md="6">
                                        <b-form-group label="Select Field">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select field"
                                            rules=""
                                            :vid="'select field'+index"
                                        >
                                            <b-form-select
                                                v-model="data.field"
                                                :ref='data.field'                                        
                                                :options="fieldOptions"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                                @input="filterCourse(index)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Select Course -->
                                    <b-col md="6">
                                        <b-form-group label="Select Course">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select course"
                                            rules="required"
                                            :vid="'select course'+index"
                                        >
                                            <b-form-select
                                                v-model="data.course"
                                                :ref='data.course'                                        
                                                :disabled="data.field.id == ''"
                                                :options="data.courseOption"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <div class="add_edu_link" style="font-weight:600;margin-bottom:10px;width:100%;display:flex;justify-content:space-between;padding:0px 14px">
                                        <a v-if="index==currentUser.fieldofIntrest.length-1" style="text-decoration:underline;color:#3b3bf6;" @click.prevent="addNewRow()">
                                            Add New Row
                                        </a>
                                        <a v-if="index!=0" class="add_edu_link_remove" style="text-decoration:underline;color:#ff0b0b" :style="index!=currentUser.fieldofIntrest.length-1?'text-align: right;display: block;width: 100%;':''" @click.prevent="removeRow(index)">
                                            Remove
                                        </a>
                                    </div>
                                </b-row>
                                <b-col cols="12">
                                </b-col>
                            </b-form>
                            </validation-observer>
                            </span>                
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>         
        </b-row>
        </validation-observer>
        <!-- <div v-if="spinner" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
       </div> -->
       <b-spinner variant="primary" v-if="spinner" large class="pageLoadSpinners center"/>
    </div>
    <b-spinner variant="primary" v-if="isSpiner" large class="pageLoadSpinners"/>
</div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required,email,max,max_value,integer,numeric,min,min_value,alpha,double } from 'vee-validate/dist/rules'
import {
  BCard, BRow, BCol, BFormGroup, BFormFile, BFormInput, BFormDatepicker, BFormRadio, BFormSelect, BForm, BSpinner, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { dbCollections } from '@/utils/firebaseCollection.js';
import { ValidationFunction } from '@/utils/globalValidations.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { Country, State, City }  from 'country-state-city';
import firebaseTimeStamp from "firebase";
import firebase from '@/utils/firebaseInit';
const db = firebase.firestore();
import {updateFiledCollectionWithStudentID} from '@/utils/addRemoveAssignStudentFromFiels.js'
import phoneCountryCodeVue from '@/views/component/phoneCountryCode.vue';
import {CountriesLength} from "@/utils/countries.js"
import createPhoneNumberRegex from '@/utils/makeRegexForVal.js';
// import { concat } from 'postcss-rtl/lib/affected-props';
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha',alpha)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)
export default{
   components:{
       BCard,
       BRow,
       BCol,
       BFormGroup,
       ValidationProvider,
       BFormFile,
       ValidationObserver,
       BFormInput,
       BFormDatepicker,
       BFormRadio,
       BFormSelect,
       BForm,
       vSelect,
       BSpinner,
       BButton,
       phoneCountryCodeVue
   }, 
   data(){
       return{
            CountriesLength,
           uploadingImage:false,
           editable:true,
           isImage:true,
           currentUser:{
                areaOfInterest:'',
              profilePic:'',
              profileName:'',
              name:'',
              firstName:'',
              lastName:'',
              email:'',
              mobileNumber:'',
              dob:'',
              gender:'',
              representative:'',              
              documentModule:[],  
              addressLine1:'',
              addressLine2:'', 
              country:'',              
              state:'',              
              city:'',
              countryOptions: [{value:null,text:'Select'}],
              stateOptions: [{value:null,text:'Select'}],
              cityOptions: [{value:null,text:'Select'}],
              pincode:'',
            //   educationDetails:[
            //       {
            //         education:"",
            //         schoolOrCollegeName:"",
            //         boardUniversityName:"",
            //         cgpa_percentage:"Percentage",
            //         cgpa_percentageValue:"",
            //         yearOfPassing:null,
            //       }
            //   ],
            educationDetails:[{
                    education:"10th/SSC",
                    schoolOrCollegeName:'',
                    boardUniversityName:"",
                    key:"10thssc",
                    cgpa_percentage:"Percentage",
                    cgpa_percentageValue:"",
                    yearOfPassing:null,
                },
                {
                    education:"12th/HSC/Diploma",
                    schoolOrCollegeName:'',
                    boardUniversityName:"",
                    key:"12thhscdiploma",
                    cgpa_percentage:"Percentage",
                    cgpa_percentageValue:"",
                    yearOfPassing:null,
                }
            ],
              language:'',
              languageOptions:[{value:null,text:'Select'},'English','Gujarati','Sanskrit','Hindi'],
              germanLanguage:'',
              exams:[],
              examOptions:[
                {title:'GRE',score:''},
                {title:'DUOLINGO',score:''},
                {title:'IELTS',score:''},
                {title:'GMAT',score:''},
                {title:'TOEFL',score:''},
                {title:'MCAT',score:''}
            ],
            fieldofIntrest:[{
                field:{
                name:'',id:''
                },
                course:{
                    name:'',id:'',fieldId:''
                },
                courseOptions:[{value:null,text:'Select'}]
            }], 
           },
           imageUrl:'',
           passingYearOptions:[],
           fieldOptions:[
                { value: null, text: 'Select' },
           ],
           fieldOfInterests:[],
           courseOptions:[],
           imageFile:[],
           fieldAndCourse:[{ value: null, text: 'Select' },],
           spinner:false, //SAVE DATA LOADER
           previewImage:[],
           isSpiner:false, //GET DATA LOADER ,

           intake: null,
           intakeOptions: [
                { value: null, text: 'Select' },
                { value: 'WS', text: 'WS' },
                { value: 'SS', text: 'SS' },
            ],

           year:null,
           yearOptions:[{value:null,text:'Select'}],
           educationOptions:[],
            schoolOrCollegeNameOptions:[],
            boardUniversityNameOptions:[],
            branchFieldOptions:[],
            snapshotListener:null,
            snapshotListener1:null,
            snapshotListener2:null,
            snapshotListener3:null,
            documentList:[],
            documentList1:[],
            documentList2:[],
            documentList3:[],
            oldEducationDetails:[],
            selectedCountry: null,
            validNumberOrNot: true,

       }
   },
   beforeDestroy() {
        if(this.snapshotListener !== null)
        {
            this.snapshotListener()
        }
        if(this.snapshotListener1 !== null)
        {
            this.snapshotListener1()
        }
        if(this.snapshotListener2 !== null)
        {
            this.snapshotListener2()
        }
        if(this.snapshotListener3 !== null)
        {
            this.snapshotListener3()
        }
    },
   watch: {
    $route: function () {    
        this.init();    
        setTimeout(()=>{
            this.getData();
        },1000)                  
    }
   },
   created(){
       var self = this;
       this.init();

        //push years
        for (let i = 0; i <= 4; i++)
        {
            self.yearOptions.push(new Date().getFullYear()+i)
        }
        self.getOptionsOfUniversity();
        self.getOptionsOfSchoolColleges();
        self.getOptionsOfEducation();
        self.getOptionsOfBranchFiels()

       setTimeout(()=>{ 
    //    self.isSpiner = true       
       this.getData();
       },1000) 
       var countries = Country.getAllCountries()
        self.currentUser.countryOptions=[{value:null,text:'Select'}]
        self.educationOptions.push({value:'', text:"select"})
        self.schoolOrCollegeNameOptions.push({value:'', text:"select"})
        self.branchFieldOptions.push({value:'', text:"select"})
        self.boardUniversityNameOptions.push({value:'', text:"select"})
        countries.forEach(data=>{
            self.currentUser.countryOptions.push({value:data,text:data.name})
        })
        var year = new Date().getFullYear()
        self.passingYearOptions.push({value:null, text:"select"})
        for (let index = year+3; index >= year-30; index--) {
            self.passingYearOptions.push({value:index, text:index})
        }
        // CANCEL UPDATE OF PROFILE DETAIL
        self.$root.$on('studentProfileCancelUpdate',()=>{
            self.$router.push({'name':'studentDetail'})
        })

        // SAVE UPDATE DETAIL
        self.$root.$on('studentProfileUpdate',()=>{
            if(self.$refs.profileStudentVali)
            {
                self.updateProfileData()
            }
        })
   },
   methods:{
        checkValidPhoneNumber(event) {
            if (this.selectedCountry !== null) {
                let regexPattern;
                const lengths = this.CountriesLength.find((e)=>e.alpha2 === this.selectedCountry.code)?.phone_number_lengths || [10,15]
                regexPattern = createPhoneNumberRegex(lengths)
                this.validNumberOrNot = regexPattern.test(event);
            }

            return this.validNumberOrNot;
        },
        onCountrySelected(country) {
            this.selectedCountry = country;
        },
        educationFiledSet(e,index,fieldId,fieldName,type) {
            var self = this 
            if(self.educationOptions.length>0 && type == 1) {
                if(e) {
                    let fIndex = self.educationOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.currentUser.educationDetails[index][fieldId] = self.educationOptions[fIndex].value
                        self.currentUser.educationDetails[index][fieldName] = self.educationOptions[fIndex].text
                    }
                }
            }
            if(self.schoolOrCollegeNameOptions && type == 2) {
                if(e) {
                    let fIndex = self.schoolOrCollegeNameOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.currentUser.educationDetails[index][fieldId] = self.schoolOrCollegeNameOptions[fIndex].value
                        self.currentUser.educationDetails[index][fieldName] = self.schoolOrCollegeNameOptions[fIndex].text
                        if(self.currentUser.educationDetails[index]['scId'] == 'others') {
                            self.currentUser.educationDetails[index]['schoolOrCollegeName'] = '';
                            self.currentUser.educationDetails[index]['scId'] = '';
                            self.currentUser.educationDetails[index][fieldName] = '';
                            self.currentUser.educationDetails[index][fieldId] = 'others';
                        } else {
                            self.currentUser.educationDetails[index][fieldName] = self.schoolOrCollegeNameOptions[fIndex].text
                        }
                    }
                }
            }
            if(self.boardUniversityNameOptions && type == 3) {
                if(e) {
                    let fIndex = self.boardUniversityNameOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.currentUser.educationDetails[index][fieldId] = self.boardUniversityNameOptions[fIndex].value
                        self.currentUser.educationDetails[index][fieldName] = self.boardUniversityNameOptions[fIndex].text
                        if(self.currentUser.educationDetails[index]['bouId'] == 'others') {
                            self.currentUser.educationDetails[index]['boardUniversityName'] = '';
                            self.currentUser.educationDetails[index]['bouId'] = '';
                            self.currentUser.educationDetails[index][fieldName] = '';
                            self.currentUser.educationDetails[index][fieldId] = 'others';
                        } else {
                            self.currentUser.educationDetails[index][fieldName] = self.boardUniversityNameOptions[fIndex].text
                        }
                    }
                }
            }
            if(self.branchFieldOptions && type == 4) {
                if(e) {
                    let fIndex = self.branchFieldOptions.findIndex(data=>data.value==e)
                    if(fIndex!=-1) {
                        self.currentUser.educationDetails[index][fieldId] = self.branchFieldOptions[fIndex].value
                        self.currentUser.educationDetails[index][fieldName] = self.branchFieldOptions[fIndex].text
                        if(self.currentUser.educationDetails[index]['bsfId'] == 'others') {
                            self.currentUser.educationDetails[index][fieldName] = '';
                            self.currentUser.educationDetails[index][fieldId] = 'others';
                        } else {
                            self.currentUser.educationDetails[index][fieldName] = self.branchFieldOptions[fIndex].text
                        }
                    }
                }
            }
        },
    //Check if is valid(image) file
    checkFile(e)
    {
        var files = e.target.files || e.dataTransfer.files;
        this.isImage=true
        if(files.length!=0)
        {
            this.previewImage=[]
            this.imageFile=[]
        }
        // this.attachmentImages=[]
        // this.attachments=[]
        files.forEach(data=>{
            if(data.type.split('/')[0]!='image')
            {
                this.isImage=false
                // this.attachmentImages=[]
            }
        })
        if(this.isImage){
            this.isImage=true
            this.createImage(files)
            // console.log(this.attachments)
        }
        else
        { 
            document.getElementById("imagePicker").value=""
        }
    },
    createImage(files) {
            //   var image = new Image();
            this.previewImage=[]
            files.forEach(data=>{
                var vm = this;
                var reader = new FileReader();
    
                reader.onload = (e) => {
                    vm.previewImage.push(e.target.result);
                    vm.imageFile.push(data)
                }
                reader.readAsDataURL(data);
            })
    },
    init(){
        var self = this; 
        self.isSpiner = true       
        getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDANDCOURSE,'isDeleted','==',false,res=>{
            self.fieldOptions = [];
            // self.fieldAndCourse = res;            
            if(res.length > 0){
                res.forEach(vals=>{
                    self.fieldOptions.push({text:vals.fieldName,value:{name:vals.fieldName,id:vals.id}})   
                        // doc.data().courses.forEach(data=>{
                    self.fieldAndCourse.push({text:vals.fieldName,id:vals.id,course:[]})
                    vals.courses.forEach(cname=>{
                        self.fieldAndCourse[self.fieldAndCourse.length-1].course.push(cname.name)
                    })
                })
                // self.isSpiner = false
            }else{
                // self.isSpiner = false
            }           
            // if(res.length > 0){                
            //     res.forEach(element=>{
            //         self.fieldOptions.push(
            //                 {
            //                 text:element.fieldName,
            //                 value:{
            //                 name:element.fieldName,
            //                 id:element.id
            //                 }
            //             }
            //         )
            //     })
            // }           
        })      
    },
    // GET ALL DATA
    getData(){
        var self = this; 
        self.isSpiner = true       
        let currentUser = JSON.parse(localStorage.getItem('currentUser'))
        // getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,currentUser.id,res=>{
            db.collection(dbCollections.USERS).doc(this.$route.params.id).get().then(res1=>{
                let res = res1.data()
                self.oldEducationDetails = JSON.parse(JSON.stringify(res.educationDetails))
            if(Object.keys(res).length > 0 && res.isDeleted == false){
                self.currentUser.profilePic = res.imageUrl;
                self.currentUser.profileName = res.imageName;
                self.currentUser.firstName=res.firstName ? res.firstName : res.name;
                self.currentUser.lastName=res.lastName ? res.lastName : res.name;
                self.currentUser.name=res.name;
                self.currentUser.email=res.email;
                self.currentUser.mobileNumber=res.mobileNumber;
                self.currentUser.gender=res.gender;
                self.currentUser.dob=res.dob;
                self.currentUser.representative=res.representative.name||'';
                self.currentUser.documentModule=res.documentModule;
                self.currentUser.addressLine1=res.addressLine1;
                self.currentUser.addressLine2=res.addressLine2;
                self.currentUser.country=res.country
                self.currentUser.state=res.state
                self.currentUser.city=res.city
                self.currentUser.pincode=res.pincode
                // self.currentUser.educationDetails = res.educationDetails
                self.currentUser.language=res.language
                self.currentUser.germanLanguage=res.germanLanguage
                self.currentUser.exams=res.exams

                self.currentUser.areaOfInterest = res.areaOfInterest||''

                self.intake = res.intake
                self.year = res.year
                self.selectedCountry = res.countryPhoneCode ? res.countryPhoneCode : { "ru": "Индия", "lt": "Indija", "tr": "Hindistan", "en": "India", "flag": "🇮🇳", "code": "IN", "dialCode": "+91", "mask": "99999 99999"}
                

                res.exams.forEach(data1=>{
                    self.currentUser.examOptions.forEach(data2=>{
                        if(data1.title == data2.title)
                        {
                            data2.score = data1.score
                        }
                    })
                })

                //Adding default filed based on whether student is new or old while edit time
                let keyVal = [];
                if(res.educationDetails.length > 0) {
                    res.educationDetails.forEach((ele)=>{
                        if(ele.key !== undefined) {
                            keyVal.push(ele.key)
                        }
                    })
                }
                if(keyVal.length == 0) {
                    res.educationDetails.forEach((ele)=>{
                        self.currentUser.educationDetails.push(ele)
                    })
                } else {
                    self.currentUser.educationDetails=res.educationDetails
                }
                // self.currentUser.fieldofIntrest=res.fieldOfInterests
                // if(res.fieldOfInterests.length > 0){
                //     self.currentUser.fieldofIntrest = [];
                //     res.fieldOfInterests.forEach((values,index)=>{
                //         self.currentUser.fieldofIntrest.push(
                //             {
                //                 'field':{name:values.field.name,id:values.field.id},
                //                 'course':values.course.name,
                //                 'courseOption':[]
                //             }                                    
                //         )
                //         self.filterCourse(index)
                //         // self.fieldOfInterests.field=doc.data().fieldOfInterests
                //     })                       
                // }
                self.getStates('e')
                self.getCities('e')
                self.isSpiner = false
            }else{
               self.isSpiner = false
               this.$router.push({name:'studentDetail'})
            }
        })
    },
    addZero(val)
    {
        if(val<10)
        {
            return `0`+val
        }
        else
        {
            return val
        }
    },
    getMaxDate()
    {
        return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
    },
    changeBirthDate(){                    
        var self = this

        var maxDate = self.getMaxDate().split('-')
        var selectedDate = self.currentUser.dob.split('-')

        if(selectedDate[0]>maxDate[0])//If Year not valid
        {
            self.currentUser.dob = '';
            return;
        }
        else if(selectedDate[0]==maxDate[0])
        {
            if(selectedDate[1]>maxDate[1])//If month not valid
            {
                self.currentUser.dob = '';
                return;
            }
            else
            {
                if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
                {
                    self.currentUser.dob = '';
                    return;
                }
            }
        }
    },    
    isOnlyDecimal(event) {
        ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
        ValidationFunction.OnlyIntegerNumber(event);
    },
    getStates(e)
    {
    var self = this

    if(e!='e')
    {
        self.currentUser.state=null
        self.currentUser.city=null
    }    
    self.currentUser.stateOptions=[{value:null,text:'Select'}]
    var states = State.getStatesOfCountry(self.currentUser.country.isoCode)
    states.forEach(data=>{
        self.currentUser.stateOptions.push({value:data,text:data.name})
    })
    },
    getCities(e)
    {
        var self = this

        if(e!='e')
        {
            self.currentUser.city=null
        }
        
        self.currentUser.cityOptions=[{value:null,text:'Select'}]
        var cities = City.getCitiesOfState(self.currentUser.country.isoCode,self.currentUser.state.isoCode)
        cities.forEach(data=>{
            self.currentUser.cityOptions.push({value:data,text:data.name})
        })
    },
    addNewRow(){    
        //add field
        this.currentUser.fieldofIntrest.push({
        field:null,
        course:null,
        courseOptions:[{value:null,text:'Select'}]
        })
          
    },
    removeRow(index)
    {
        //remove field
        this.currentUser.fieldofIntrest.splice(index, 1)
    },   
    filterCourse(index)
    {
            // var self = this
            // self.currentUser.fieldofIntrest[index].courseOptions=[{value:null,text:'Select'}]
            // self.fieldAndCourse.forEach(data=>{              
            //     if(data.id==self.currentUser.fieldofIntrest[index].field.id)
            //     {
            //         self.currentUser.fieldofIntrest[index].courseOptions.push(data)                
            //     }
            // })
            var self = this  
            if(self.fieldAndCourse.length > 0){        
                self.fieldAndCourse.forEach(data=>{                 
                    if(data.id==self.currentUser.fieldofIntrest[index].field.id)
                    {
                        if(self.currentUser.fieldofIntrest[index].courseOption == undefined){
                            self.currentUser.fieldofIntrest[index].courseOption = [];
                        }                        
                        self.currentUser.fieldofIntrest[index].courseOption = data.course
                    }
                })
            }
    },
    uploaduserProfileFile(data,cb){
        this.uploadingImage=true
        uploadImageFile.removeImage('/Student Images',this.currentUser.profileName,()=>{
        })
        uploadImageFile.uploadImageFile('Student Images',data.name,data,res=>{
            this.uploadingImage=false
            cb(res)
        })           
    },
    updateProfileData(){
        var self = this
        
        // var fieldOfInterests = []
        // self.currentUser.fieldofIntrest.forEach((data,index)=>{
        //     console.log("Field of Interest",data.field.id)
        //     if(data.field.id!=null && data.field.id!='')
        //     {
        //         fieldOfInterests.push(data)
        //     }
        // })
        // self.currentUser.fieldofIntrest = fieldOfInterests
        self.checkValidPhoneNumber(self.currentUser.mobileNumber);

        self.$refs.profileStudentVali.validate().then(success => {
            if (success && self.validNumberOrNot) {
                self.currentUser.name = self.currentUser.firstName + ' ' + self.currentUser.lastName
                this.spinner = true;
                if(self.imageFile.length==0){
                 let fieldofCourse = [];   
                 if(this.currentUser.fieldofIntrest.length > 0){
                    this.currentUser.fieldofIntrest.forEach(elements=>{
                        fieldofCourse.push(
                            {
                            'course':{fieldId:elements.field.id,id:elements.field.id,name:elements.course},
                            'field':{id:elements.field.id,name:elements.field.name}
                            }
                        )
                    })
                }
                 let updateObject = {
                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),                    
                    'name':self.currentUser.name,
                    'firstName':self.currentUser.firstName,
                    'lastName':self.currentUser.lastName,
                    'email':self.currentUser.email,
                    'mobileNumber':self.currentUser.mobileNumber,
                    'dob':self.currentUser.dob,
                    'gender':self.currentUser.gender,
                    'addressLine1':self.currentUser.addressLine1,
                    'addressLine2':self.currentUser.addressLine2,
                    'country':self.currentUser.country,
                    'state':self.currentUser.state,
                    'city':self.currentUser.city,
                    'pincode':self.currentUser.pincode,
                    'educationDetails':self.currentUser.educationDetails,
                    'language':self.currentUser.language,
                    'germanLanguage':self.currentUser.germanLanguage,
                    'exams':self.currentUser.exams,
                    // 'fieldOfInterests':fieldofCourse,
                    'intake':self.intake,
                    'year':self.year,

                    'areaOfInterest':self.currentUser.areaOfInterest,
                    'countryPhoneCode': self.selectedCountry
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.$route.params.id,updateObject,res=>{
                    let count1 = 0;
                    const studentUpdateFunRem = (ele1) => {
                        if(self.oldEducationDetails.length <= count1) {
                            let count2 = 0;
                            const studentUpdateFun = (ele) => {
                                if(self.currentUser.educationDetails.length <= count2) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Document Update Successfully',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                    })

                                    this.spinner = false;
                                    this.$root.$emit('stopSpinner')
                                    self.$router.push({'name':'studentDetail'})
                                    //UPDATE USERNAME
                                    let currUser= JSON.parse(localStorage.getItem('userData'))
                                    currUser.fullName = self.currentUser.name;
                                    currUser.username = self.currentUser.name;                 
                                    localStorage.setItem('userData',JSON.stringify(currUser))
                                    self.$root.$emit('changeLoggedUserName',self.currentUser.name)
                                    return;
                                } else {
                                    updateFiledCollectionWithStudentID(ele,this.$route.params.id,1).then(()=>{
                                        count2++;
                                        studentUpdateFun(self.currentUser.educationDetails[count2])
                                    }).catch(()=>{
                                        count2++;
                                        studentUpdateFun(self.currentUser.educationDetails[count2])
                                    })
                                }
                            }
                            studentUpdateFun(self.currentUser.educationDetails[count2])
                        } else {
                            updateFiledCollectionWithStudentID(ele1,this.$route.params.id,2).then(()=>{
                                count1++;
                                studentUpdateFunRem(self.oldEducationDetails[count1])
                            }).catch(()=>{
                                count1++;
                                studentUpdateFunRem(self.oldEducationDetails[count1])
                            })
                        }
                    }
                    studentUpdateFunRem(self.oldEducationDetails[count1])
                })                                
                }else{                   
                    if(self.currentUser.profilePic !== "" || self.currentUser.profilePic !== undefined){
                        let count = 0;
                        let fileArry = self.imageFile;                                              
                        const uploadFileFunction = (row) => {
                        if(count >= fileArry.length){                                                   
                            return;                
                        }
                        this.uploaduserProfileFile(row,(saveRes)=>{             
                            if(count >= Number(fileArry.length-1)){
                                let fieldofCourse = [];   
                                if(this.currentUser.fieldofIntrest.length > 0){
                                    this.currentUser.fieldofIntrest.forEach(elements=>{
                                        fieldofCourse.push(
                                            {
                                            'course':{fieldId:elements.field.id,id:elements.field.id,name:elements.course},
                                            'field':{id:elements.field.id,name:elements.field.name}
                                            }
                                        )
                                    })
                                }
                                let saveObj = {
                                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),                    
                                    'name':self.currentUser.name,
                                    'firstName':self.currentUser.firstName,
                                    'lastName':self.currentUser.lastName,
                                    'email':self.currentUser.email,
                                    'mobileNumber':self.currentUser.mobileNumber,
                                    'dob':self.currentUser.dob,
                                    'gender':self.currentUser.gender,
                                    'addressLine1':self.currentUser.addressLine1,
                                    'addressLine2':self.currentUser.addressLine2,
                                    'country':self.currentUser.country,
                                    'state':self.currentUser.state,
                                    'city':self.currentUser.city,
                                    'pincode':self.currentUser.pincode,
                                    'educationDetails':self.currentUser.educationDetails,
                                    'language':self.currentUser.language,
                                    'germanLanguage':self.currentUser.germanLanguage,
                                    'exams':self.currentUser.exams,
                                    // 'fieldOfInterests':fieldofCourse,
                                    'imageUrl': saveRes.imageUrl,
                                    'imageName':saveRes.imageName,

                                    'areaOfInterest':self.currentUser.areaOfInterest,

                                    'intake':self.intake,
                                    'year':self.year,
                                    'countryPhoneCode': self.selectedCountry
                                }
                                self.$root.$emit('changeProfile',saveRes.imageUrl)
                                var userData= JSON.parse(localStorage.getItem('userData'))
                                userData.avatar = saveRes.imageUrl
                                localStorage.setItem('userData',JSON.stringify(userData))
                                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.$route.params.id,saveObj,res=>{
                                    let count1 = 0;
                                    const studentUpdateFunRem = (ele) => {
                                        if(self.oldEducationDetails.length <= count1) {
                                            let count2 = 0;
                                            const studentUpdateFun = (ele) => {
                                                if(self.currentUser.educationDetails.length <= count2) {
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        position: 'top-right',
                                                        props: {
                                                            title: 'Document Update Successfully',
                                                            icon: 'CoffeeIcon',
                                                            variant: 'success',                               
                                                        },
                                                    })
                                                    this.spinner = false;
                                                    this.$root.$emit('stopSpinner')
                                                    //UPDATE USERNAME
                                                    let currUser= JSON.parse(localStorage.getItem('userData'))
                                                    currUser.fullName = self.currentUser.name;
                                                    currUser.username = self.currentUser.name;
                                                    localStorage.setItem('userData',JSON.stringify(currUser))
                                                    self.$root.$emit('changeLoggedUserName',self.currentUser.name)
                                                    this.$router.push({'name':'studentDetail'})  
                                                    return;
                                                } else {
                                                    updateFiledCollectionWithStudentID(ele,this.$route.params.id,1).then(()=>{
                                                        count2++;
                                                        studentUpdateFun(self.currentUser.educationDetails[count2])
                                                    }).catch(()=>{
                                                        count2++;
                                                        studentUpdateFun(self.currentUser.educationDetails[count2])
                                                    })
                                                }
                                            }
                                            studentUpdateFun(self.currentUser.educationDetails[count2])
                                        } else {
                                            updateFiledCollectionWithStudentID(ele,this.$route.params.id,2).then(()=>{
                                                count1++;
                                                studentUpdateFunRem(self.oldEducationDetails[count1])
                                            }).catch(()=>{
                                                count1++;
                                                studentUpdateFunRem(self.oldEducationDetails[count1])
                                            })
                                        }
                                    }
                                    studentUpdateFunRem(self.oldEducationDetails[count1])                             
                                }) 
                            }else{
                            uploadFileFunction(fileArry[count]);
                            }
                        }) 
                       } 
                        uploadFileFunction(fileArry[count]);
                    }else{
                        this.spinner = false;
                        this.$root.$emit('stopSpinner')
                    }
                    // imageUrl
                    // imageName
                }
            }else{
                this.spinner = false;
                this.$root.$emit('stopSpinner')
            }
        })
    },
    addNewEducationRow()
    {
        this.currentUser.educationDetails.push({
            education:"",
            schoolOrCollegeName:"",
            boardUniversityName:"",
            cgpa_percentage:"Percentage",
            branchOrStreamOrFieldName:'',
            cgpa_percentageValue:"",
            yearOfPassing:null,
            edId:'',
            scId:'',
            bouId:'',
            bsfId:'',
        })
    },
    removeEducationRow(index)
    {
        this.currentUser.educationDetails.splice(index,1)
    },
    getOptionsOfEducation() {
            var self = this
            try {     
                this.isSpinner = true;
                if(this.snapshotListener !== null) {
                    this.snapshotListener();
                }
                self.snapshotListener = db.collection(dbCollections.DEGREEFIELD).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList.splice(index, 1);
                            }
                        }
                    })
                    self.documentList.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.educationOptions = [{value:'', text:"select"}];
                    self.$nextTick(()=>{
                        self.documentList = self.documentList.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList.forEach((ele)=>{
                            self.educationOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfSchoolColleges() {
            var self = this
            try {     
                this.isSpinner = true;
                if(self.snapshotListener1 !== null) {
                    self.snapshotListener1();
                }
                self.snapshotListener1 = db.collection(dbCollections.COLLEGESFIEDLS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList1 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList1.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList1[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList1.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList1[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList1.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList1.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList1.splice(index, 1);
                            }
                        }
                    })
                    self.documentList1.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.schoolOrCollegeNameOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList1 = self.documentList1.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList1.forEach((ele)=>{
                            self.schoolOrCollegeNameOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.schoolOrCollegeNameOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfUniversity() {
            var self = this;
            try {     
                if(self.snapshotListener2 !== null) {
                    self.snapshotListener2();
                }
                this.isSpinner = true;
                self.snapshotListener2 = db.collection(dbCollections.UNIVERSITIESFIELDS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList2 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList2.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList2[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList2.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList2[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList2.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList2.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList2.splice(index, 1);
                            }
                        }
                    })
                    self.documentList2.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.boardUniversityNameOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList2 = self.documentList2.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList2.forEach((ele)=>{
                            self.boardUniversityNameOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.boardUniversityNameOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        },
        getOptionsOfBranchFiels() {
            var self = this;
            try {     
                if(self.snapshotListener3 !== null) {
                    self.snapshotListener3();
                }
                this.isSpinner = true;
                self.snapshotListener3 = db.collection(dbCollections.BRANCHSTREAMFIELDS).where("isDeleted",'==',false)
                    .onSnapshot({includeMetadataChanges: true},(snapshot) => {
                    if (snapshot.empty) {
                        this.isSpinner = false;
                        // console.error(snapshot);
                        self.documentList3 = [];
                        return;
                    }
                    snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                        if(change.type === "added") {
                            let index = self.documentList3.findIndex((ele)=>{
                                return ele.id == change.doc.data().id
                            })
                            if(index > -1) {
                                let obj = {...change.doc.data()};
                                self.documentList3[index] = obj
                            } else {
                                if(!change.doc.metadata.hasPendingWrites) {
                                    let obj = {...change.doc.data()};
                                    self.documentList3.push(obj);
                                }
                            }
                        }
                        if(change.type === "modified") {
                            let index = self.documentList3.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList3[index] = {...change.doc.data()};
                            } 
                            else {
                                let obj = {...change.doc.data()};
                                self.documentList3.push({...obj});
                            }
                        }
                        if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                            let index = self.documentList3.findIndex((x) => x.id === change.doc.id);
                            if(index > -1) {
                                self.documentList3.splice(index, 1);
                            }
                        }
                    })
                    self.documentList3.sort((a, b) => {
                        const t1 = a.name.toLowerCase();
                        const t2 = b.name.toLowerCase();
                        
                        if (t1 < t2) {
                            return -1;
                        } else if (t1 > t2) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    self.branchFieldOptions = [{value:'', text:"select",disabled: true}]
                    self.$nextTick(()=>{
                        self.documentList3 = self.documentList3.filter((ele)=>{
                            return ele.isDeleted == false
                        })
                        self.documentList3.forEach((ele)=>{
                            self.branchFieldOptions.push({
                                value:ele.id,
                                text:ele.name
                            })
                        })
                        self.branchFieldOptions.push({
                            value:'others',
                            text:'Others'
                        })
                        self.$forceUpdate();
                        self.isSpinner = false
                    })
                })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
            }
        }
   }   
}
</script>
<style scoped>